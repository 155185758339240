<template>
  <div class="content">
    <div class="box1">
      <div class="left">
        <div class="left-title">智慧城市数据中心</div>
        <div class="left-text">
          <p>城市大数据中心</p>
          <p>国家A级计算机机房</p>
          <p>国家三级顶保标准（Tier3+）</p>
          <p>超大机房</p>
          <p>快速部署</p>
          <p>高设备利用率</p>
          <p>数据完整备份低风险</p>
          <p>电信级别托管，一站式运维</p>
          <p>集中托管到城市大数据中心平台，低能耗</p>
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.content {
  padding: 0 16%;
  min-height: calc(100vh - 317px);
  .box1 {
    width: 100%;
    height: 430px;
    display: flex;
    background-color: #fff;
    margin-top: 30px;
    padding: 30px;
    .left {
      width: 50%;
      padding: 5% 10%;
      box-sizing: border-box;
      .left-title {
        font-size: 28px;
        font-weight: 600;
        padding: 30px 0;
        color: #333333;
      }
      .left-text {
        font-size: 16px;
        font-weight: 400;
        color: #6b6b6b;
      }
    }
    .right {
      width: 50%;
      height: 100%;
      background: url("../../../../assets/image/cityImage/img1.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>